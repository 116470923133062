import React from "react"
import { graphql, StaticQuery } from "gatsby"
import facebookLogo from "../../images/f_logo_RGB-Blue_1024.png"

// Styled components
import { FooterContainer, Column } from "./footer.styles"

const Footer = () => (
  <StaticQuery
    query={graphql`
      query {
        allMarkdownRemark {
          edges {
            node {
              fields {
                slug
              }
              frontmatter {
                title
              }
              fileAbsolutePath
            }
          }
        }
      }
    `}
    render={data => {
      const { edges } = data.allMarkdownRemark
      return (
        <FooterContainer>
          <Column>
            <h3>Navigation </h3>{" "}
            <ul>
              {edges.map(page => (
                <li>
                  {" "}
                  <a href={page.node.fields.slug}>
                    {" "}
                    {page.node.frontmatter.title}{" "}
                  </a>
                </li>
              ))}
            </ul>
          </Column>
          <Column>
            <h3>Contact</h3>{" "}
            <ul>
              <li> Phone: 01922 712955</li>
              <li> Address: Victoria Ave, Walsall WS3 3HS</li>
            </ul>
          </Column>
          <Column>
            <h3>Other Links</h3>{" "}
            <ul>
              <li>
                <a href="https://www.methodist.org.uk/">
                  Methodist Church of Great Britain
                </a>
              </li>
              <li>
                <a href="https://brownhillsandwillenhallmethodistcircuit.org.uk">
                  Brownhills and Willenhall Circuit
                </a>
              </li>
              <li>
                <a href="https://www.facebook.com/StJohnsMC/">
                  <img src={facebookLogo} />
                </a>
              </li>
            </ul>
          </Column>
        </FooterContainer>
      )
    }}
  />
)

export default Footer
