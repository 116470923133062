import PropTypes from "prop-types"
import { useState } from "react"
import { jsx } from "@emotion/core"
import React, { Fragment } from "react"
import { graphql, StaticQuery } from "gatsby"
import Burger from "@animated-burgers/burger-rotate"
// don't forget the styles
import "@animated-burgers/burger-rotate/dist/styles.css"
/** @jsx jsx */

// Assets
import logo from "../../images/saintJohnsLogo.svg"

// Styled components
import {
  Logo,
  LogoContainer,
  NavBar,
  NavLink,
  NavContainer,
  Dropdown,
  DropdownLink,
  DropdownHeader,
  NavbarItem,
  BurgerContainer,
  Menu,
  MenuList,
  MenuLink,
} from "./header.styles"

const Header = () => {
  const [menuOpen, setMenuOpen] = useState(false)
  return (
    <StaticQuery
      query={graphql`
        query {
          allFile(filter: { relativeDirectory: { eq: "navigationContent" } }) {
            edges {
              node {
                childMarkdownRemark {
                  frontmatter {
                    order {
                      dropDown {
                        groupTitle
                        link {
                          link {
                            Label
                            URL
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      `}
      render={data => {
        const {
          order: navItems,
        } = data.allFile.edges[0].node.childMarkdownRemark.frontmatter
        return (
          <NavContainer>
            <NavBar>
              <LogoContainer>
                <Logo src={logo} alt="st johns logo" />
              </LogoContainer>
              {navItems.map((item, index) => (
                <NavbarItem key={index}>
                  {item.dropDown.link.length === 1 ? (
                    <NavLink
                      activeClassName="active"
                      to={item.dropDown.link[0].link.URL}
                      key={item.dropDown.link[0].link.Label}
                    >
                      {item.dropDown.link[0].link.Label}
                    </NavLink>
                  ) : (
                    <div css={Dropdown} tabindex="0">
                      <DropdownHeader>
                        <div>{item.dropDown.groupTitle}</div>
                      </DropdownHeader>
                      {item.dropDown.link.map(link => (
                        <NavLink
                          css={DropdownLink}
                          activeClassName="active"
                          to={link.link.URL}
                          key={link.link.Label}
                        >
                          {link.link.Label}
                        </NavLink>
                      ))}
                    </div>
                  )}
                </NavbarItem>
              ))}
              <BurgerContainer>
                <Burger
                  isOpen={menuOpen}
                  onClick={() => setMenuOpen(!menuOpen)}
                />
              </BurgerContainer>
            </NavBar>
            {menuOpen && (
              <Menu>
                {" "}
                <MenuList>
                  {navItems.map((item, index) => (
                    <Fragment key={index}>
                      {item.dropDown.link.length === 1 ? (
                        <MenuLink
                          onClick={() => setMenuOpen(!menuOpen)}
                          activeClassName="active"
                          to={item.dropDown.link[0].link.URL}
                          key={item.dropDown.link[0].link.Label}
                        >
                          {item.dropDown.link[0].link.Label}
                        </MenuLink>
                      ) : (
                        <>
                          {item.dropDown.link.map(link => (
                            <MenuLink
                              onClick={() => setMenuOpen(!menuOpen)}
                              activeClassName="active"
                              to={link.link.URL}
                              key={link.link.Label}
                            >
                              {link.link.Label}
                            </MenuLink>
                          ))}
                        </>
                      )}
                    </Fragment>
                  ))}
                </MenuList>
              </Menu>
            )}
          </NavContainer>
        )
      }}
    />
  )
}

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header
