import styled from "@emotion/styled"
import { css } from "@emotion/core"
import {
  logoRed,
  darkGrey,
  offWhite,
  logoOrange,
  Gloria,
} from "../../globalCss"
import { Link } from "gatsby"

export const NavContainer = styled.header`
  z-index: 99;
  position: fixed;
  width: 100%;
  top: 0;
  height: 6.2rem;
  display: flex;
  justify-content: center;
  .active {
    color: ${logoRed};
  }
  box-shadow: 3px -3px 0px 0px rgba(82, 82, 82, 0.76);
`
export const NavBar = styled.div`
  position: relative;
  height: 6.2rem;
  width: 100%;
  margin: 0 auto;
  display: flex;
  background-color: ${offWhite};
  justify-content: flex-start;
  align-items: stretch;
  line-height: 1;
  max-width: 1200px;
  margin: 0 auto;
  box-shadow: 0px 0px 5px 0px rgba(82, 82, 82, 0.76);
  border-left: ${darkGrey} 1px solid;
  border-right: ${darkGrey} 1px solid;
`

export const NavbarItem = styled.div`
  position: relative;
  position: relative;
  display: flex;
  width: 15rem;
  justify-content: center;
  * {
    pointer-events: none;
  }

  &:hover * {
    pointer-events: auto;
  }
`

export const NavLink = styled(Link)`
  ${Gloria}
  height:6.2rem;
  font-size: 2rem;
  width: 100%;
  color: ${logoOrange};
  text-decoration: none;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;

  @media only screen and (max-width: 800px) {
    display: none;
  }
`

export const LogoContainer = styled.div`
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: flex;
  margin-left: 0.2rem;
`

export const Logo = styled.img`
  height: 5rem;
  margin-left: 0.2rem;
`

export const DropdownLink = css`
  ${Gloria}
  height:6.2rem;
  font-size: 2rem;
  color: ${logoOrange};
  text-decoration: none;
  padding: 0rem 2rem;
  background-color: ${offWhite};
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  opacity: 0;
  transition: opacity ease-in-out 0.5s;
  pointer-events: none;

  @media only screen and (max-width: 800px) {
    display: none;
  }
`
export const Dropdown = css`
  position: relative;
  z-index: 6;
  position: absolute;
  width: 100%;
  min-width: 12rem;

  &:focus {
    outline: none;
  }

  &:hover {
    box-shadow: 2px 2px 5px 0px rgba(82, 82, 82, 0.76);
  }

  &:hover a {
    opacity: 100;
    pointer-events: auto;
  }

  @media (any-pointer: none) {
    &:focus {
      box-shadow: 4px 0px 0px 0px rgba(82, 82, 82, 0.76);
    }
    &:focus-within a {
      opacity: 100;
      cursor: pointer;
    }
  }

  @media only screen and (max-width: 800px) {
    display: none;
  }
`

export const DropdownHeader = styled.div`
  ${Gloria}
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 2rem;
  color: ${logoOrange};
  text-decoration: none;
  margin: 0 2rem;
  height: 6.2rem;
`

export const BurgerContainer = styled.div`
  margin-left: auto;
  margin-right: 1.5rem;
  display: flex;
  align-items: center;
  justify-content: center;
  display: none;

  @media only screen and (max-width: 800px) {
    display: flex;
  }
`

export const Menu = styled.div`
  display: none;
  @media only screen and (max-width: 800px) {
    display: initial;
    position: fixed;
    height: calc(100vh - 6.2rem);
    top: 6.2rem;
    width: 100vw;
    background-color: ${offWhite};
    border: ${darkGrey} 1px solid;
    z-index: 999;
  }
`

export const MenuList = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
`

export const MenuLink = styled(Link)`
  ${Gloria}
  height:6.2rem;
  font-size: 2rem;
  width: 100%;
  color: ${logoOrange};
  text-decoration: none;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
`
