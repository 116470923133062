import styled from "@emotion/styled"
import { lightGrey, offWhite } from "../../globalCss"

export const Main = styled.main`
  max-width: 1200px;
  margin: 0 auto;
  background-color: ${offWhite};
  box-shadow: 0px 0px 33px -3px rgba(82, 82, 82, 0.76);
`
export const Background = styled.div`
  position: relative;
  top: 6.2rem;
`
