import styled from "@emotion/styled"
import {
  offWhite,
  Fira,
  darkGrey,
  logoOrangeLighten,
  logoRed,
} from "../../globalCss"
import { Link } from "gatsby"

export const FooterContainer = styled.footer`
  ${Fira}
  color: ${darkGrey};
  margin: 0 auto;
  position: relative;
  max-width: 1200px;
  background-color: ${logoOrangeLighten};
  border-left: ${darkGrey} 1px solid;
  border-right: ${darkGrey} 1px solid;
  border-bottom: ${darkGrey} 1px solid;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 2rem 5rem;
  z-index: 9999;
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
  justify-content: space-around;
`

export const Column = styled.div`
  flex-grow: 1;
  flex-shrink: 0;
  h3 {
    color: ${logoRed};
    font-weight: bold;
    font-size 1.5rem;
    margin-bottom: .5rem;
  }
  ul {
    list-style: none;
    padding: 0;
    margin:0;
    font-size 1.2rem;
    line-height: 1.2;
    margin-bottom: 1rem;
    
  a {
      color: inherit;
      text-decoration: none; 

      &:hover {
        color: ${logoRed}
      }
  }

    img {
      height: 3rem;
      width: 3rem;
      margin: 0.5rem 0rem;
    }
  }
`
