import { css } from "@emotion/core"
import emotionNormalize from "emotion-normalize"
import { fontImports } from "./fonts"
import { lightGrey, darkGrey } from "./colors"

export const baseCss = css`
  ${fontImports}
  ${emotionNormalize}
  h1, h2, h3, h4, h5, h6 {
    margin: 0;
    padding: 0;
    border: 0;
    font-size: 100%;
    font: inherit;
    vertical-align: baseline;
  }
  * {
    box-sizing: border-box;
  }
  html {
    font-size: 62.5%;
    @media screen and (max-width: 1000px) {
      font-size: 58.5%;
    }
    @media screen and (max-width: 800px) {
      font-size: 54.6%;
    }
    @media screen and (max-width: 600px) {
      font-size: 50.5%;
    }
    @media screen and (max-width: 400px) {
      font-size: 46.5%;
    }
  }
  body {
    background-color: ${lightGrey};
  }
  .burger .burger-lines,
  .burger .burger-lines:after,
  .burger .burger-lines:before {
    background-color: ${darkGrey};
  }
`
