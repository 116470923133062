import { css } from "@emotion/core"

export const fontImports = css`
  @import url("https://fonts.googleapis.com/css?family=Gloria+Hallelujah&display=swap");
  @import url("https://fonts.googleapis.com/css?family=Fira+Sans&display=swap");
`
export const Gloria = css`
  font-family: "Gloria Hallelujah", cursive;
`

export const Fira = css`
  font-family: "Fira Sans", sans-serif;
`
